import http from "../http-common";

class FileUploadService {
  upload(file, onUploadProgress) {
    /*let formData = new FormData();
    formData.append("slider", file);
	
	

    return http.post("/sliders", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });*/
	/*fetch(
			'https://indiancitymarket.com/api/sliders',
			{
				method: 'POST',
				body: formData,
			}
		).then((response) => response.json())
		.then((result) => {
			console.log(result)
		})*/
		
		const formData = new FormData();

		formData.append('slider',file);

		fetch(
			'https://indiancitymarket.com/api/sliders',
			{
				method: 'POST',
				body: formData,
			}
		)
		.then((response) => response.json())
		.then((result) => {
				console.log(result)
				if(result.status){
					//this.setState({previewImage:'https://indiancitymarket.com/public/images/'+result.data})
					//console.log(result.data)
					return onUploadProgress;
				}
			})
			.catch((error) => {
				console.error('Error:', error);
			});
  }

  getFiles() {
    return http.get("/files");
  }
}

export default new FileUploadService();