import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Button } from 'reactstrap';

class ListItem extends Component {
  render() {
    const { match } = this.props;
    return (
      <Fragment>
        <Button>
          <Link to={`${match.path}/new`}>New Item</Link>
        </Button>
        <h3>List Item</h3>
      </Fragment>
    );
  }
}

export default ListItem;
