import React, { Fragment,Component } from "react";
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { format } from 'date-fns'
//import {Button,TextField} from '@material-ui/core';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { createProduct } from "../actions/products";
import Multiselect from 'multiselect-react-dropdown';
import { ToastContainer, toast } from 'react-toastify';
import FileUploadComponent from './fileUpload.component';
import 'react-toastify/dist/ReactToastify.css';
//import './NewProduct.css';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import {retrieveCategories} from "../actions/categories"

const options = [
    {
      name: "Annie Cruz",
      value: "annie.cruz",
      photo: "https://randomuser.me/api/portraits/women/60.jpg"
    },
    {
      name: "Eli Shelton",
      disabled: true,
      value: "eli.shelton",
      photo: "https://randomuser.me/api/portraits/men/7.jpg"
    },
    {
      name: "Loretta Rogers",
      value: "loretta.rogers",
      photo: "https://randomuser.me/api/portraits/women/51.jpg"
    },
    {
      name: "Lloyd Fisher",
      value: "lloyd.fisher",
      photo: "https://randomuser.me/api/portraits/men/34.jpg"
    },
    {
      name: "Tiffany Gonzales",
      value: "tiffany.gonzales",
      photo: "https://randomuser.me/api/portraits/women/71.jpg"
    }
  ];


class NewProduct extends React.Component {
	  constructor(props) {
    super(props);
    this.state = {
		laxman:[],
		id: 1,
		user_id:2,
		category_id:null,
		title: "",
		unit_qty: null,
		price: null,
		original_price: null,
		discount_price: null,
		media_type: null,
		description: "",
		is_stock:1,
		previewImage:null,
		selectedValue:""
    };
  }
    /*handleFormChange = event => {
    let productsParamsNew = { ...this.state.productParams };
    let val = event.target.value;
    productsParamsNew[event.target.name] = val;
    this.setState({
      loginParams: productsParamsNew
    });
  };*/
  
    /*onChangeTitle(e) {
    this.setState({
      title: e.target.value,
    });
  }

  onChangeDescription(e) {
    this.setState({
      description: e.target.value,
    });
  }*/
  
  componentWillMount(){
	  this.props.retrieveCategories();
	  //this.setState({laxman:cats})
  }

  saveProduct() {
	  const totaldata = this.state;
	  console.log(totaldata)
    const { user_id,category_id,title,media_type,unit_qty,price,original_price,discount_price,description,is_stock } = this.state;
    this.props
      .createProduct(user_id,category_id,title,unit_qty,media_type,price,original_price,discount_price,description,is_stock)
      .then((data) => {
        console.log("Resposne from store");
		console.log(data);
		toast("Product Added Successfully");
		this.setState({title: '',unit_qty:'',price:'',original_price:''});
      })
      .catch((e) => {
        console.log(e);
      });
  }

  newProduct() {
    this.setState({
      id: null,
      title: "",
      description: "",
      published: false,

      submitted: false,
    });
  }
  categoryChange(e){
	  console.log(e.target.value)
	  this.setState({category_id:e.target.value})
  }
	addProduct = event => {
		event.preventDefault();
		alert("Add product");
    /*let user_id = this.state.loginParams.user_id;
    let user_password = this.state.loginParams.user_password;
    if (user_id === "admin" && user_password === "123456") {
      localStorage.setItem("token", "T");
      this.setState({
        islogged: true
      });
    }*/
	//this.setState({name: e.target.value});
	  console.log("Get Details")
	  /*try{
		 // Simple POST request with a JSON body using axios
	  const product = { user_id:1,category_id:1,title:'New Product',media_type:'no_photo.png',unit_qty:100,price:1001,original_price:1001,discount_price:999,description:'testing details',is_stock:1 };
	  axios.post('https://indiancitymarket.com/api/products', product)
	  .then(response => /*this.setState({ articleId: response.data.id }) console.log(response))
	  .catch(error=>console.log(error)); 
	  }catch(ex){
		  console.log(ex)
	  }*/
	  
    
  };
  
  selectFile(event) {
		console.log("Upload file")
		console.log(event.target.files[0])
		//this.setState({previewImage:URL.createObjectURL(event.target.files[0].name)})
		const formData = new FormData();

		formData.append('image', event.target.files[0]);

		fetch(
			'https://indiancitymarket.com/api/uploadImage',
			{
				method: 'POST',
				body: formData,
			}
		)
		.then((response) => response.json())
		.then((result) => {
				console.log(result)
				if(result.status){
					this.setState({previewImage:'https://indiancitymarket.com/public/images/'+result.data})
				}
				//console.log(URL.createObjectURL(event.target.files))
				//this.setState({media_type:result.data,previewImage: URL.createObjectURL(event.target.files[0])});
				this.setState({media_type:result.data});
				//console.log('Success:', result);this.setState({photo:result.name,previewImage: URL.createObjectURL(event.target.files[0])});
			})
			.catch((error) => {
				console.error('Error:', error);
			});
			
		/*var formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append('name', 'some value user types');
    formData.append('description', 'some value user types');
    console.log(event.target.files[0]);

    fetch('https://exlraethealth.in/backend/api/uploadImage', {
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data','X-XSRF-TOKEN':'{{xsrf-token()}}'},
        body: {photo:event.target.files[0]}
    })
    .then((response) => response.json())
    .then((data) => {
        //this.setState({images: data.images, isLoading: false});
        //this.props.updateImages(data.images);
		console.log(data)
    })
    .catch(error => this.setState({error, isLoading: false}));*/
		/*const fileInput = event.target.files[0];
		const formData = new FormData();
		formData.append('file', fileInput);
    this.setState({currentFile: event.target.files[0],previewImage: URL.createObjectURL(event.target.files[0]),progress: 0,message: ""});
	const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data','Access-Control-Allow-Origin':'*','Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept','X-XSRF-TOKEN':'{{xsrf-token()}}' },
        //body: JSON.stringify({ photo: event.target.files[0] })
		body: formData,
		};
		try{
            fetch('https://exlraethealth.in/backend/api/uploadImage', requestOptions)
        .then(response => response.json())
        .then(data => {
    const letters = data.letters;
    console.log(letters);
  });
        }catch(e){
            console.log('error', e);        
        }*/
		
  }
  
  	onSelect(selectedList, selectedItem) {
    console.log(selectedList)
	console.log(selectedItem.id)
	//this.setState({sid:selectedItem.id})
}

onRemove(selectedList, removedItem) {
    console.log(selectedList)
	console.log(removedItem)
}
  
  render() {
	  const {previewImage} = this.state;
	  const { categories } = this.props;
    return (
      <Fragment>
        <Button color="secondary" size="md" onClick={() => {
            this.props.history.goBack();
          }}
        >
          Back
        </Button>
		<br/>
		<ToastContainer />
		<div className="tm-bg-primary-dark">
		<form className="form-signin">
          <div className="row">
        <div className="col-xl-9 col-lg-10 col-md-12 col-sm-12 mx-auto">
          <div className="tm-block tm-block-h-auto">
            <div className="row">
              <div className="col-12">
                <h2 className="tm-block-title d-inline-block">Add New Product</h2>
              </div>
            </div>
            <div className="row tm-edit-product-row">
              <div className="col-xl-6 col-lg-6 col-md-12">                
                  <div className="form-group mb-3">
                    <label htmlFor="name">Product Name
                    </label>
                    <input id="title" name="title" type="text" onChange={(e)=>this.setState({title:e.target.value})} className="form-control validate" required=""/>
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="description">Description</label>
                    <textarea className="form-control validate" name="description" id="description" onChange={(e)=>this.setState({description:e.target.value})} rows="3" required=""></textarea>
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="category">Category</label>
                    <select className="form-contorl" id="category_id" name="category_id" onChange={this.categoryChange.bind(this)}>
                      <option>Select category</option>
                      {
						categories.map((cat,index)=>
						<option key={index} value={cat.id}>{cat.name}</option>
						)
						}
                    </select>
                  </div>
                  <div className="row">
                      <div className="form-group mb-3 col-xs-12 col-sm-6">
                          <label htmlFor="expire_date">Price
                          </label>
                          <input id="price" name="price" onChange={(e)=>this.setState({price:e.target.value})} type="text" className="form-control validate hasDatepicker" data-large-mode="true"/>
                        </div>
                        <div className="form-group mb-3 col-xs-12 col-sm-6">
                          <label htmlFor="stock">Units In Stock
                          </label>
                          <input id="stock" name="stock" type="text" onChange={(e)=>this.setState({unit_qty:e.target.value})} className="form-control validate" required=""/>
                        </div>
                  </div>
				  <div className="row">
                      <div className="form-group mb-3 col-xs-12 col-sm-6">
                          <label htmlFor="expire_date">Orignal Price
                          </label>
                          <input id="original_price" name="original_price" onChange={(e)=>this.setState({original_price:e.target.value})} type="text" className="form-control validate hasDatepicker" data-large-mode="true"/>
                        </div>
                        <div className="form-group mb-3 col-xs-12 col-sm-6">
                          <label htmlFor="stock">Discount
                          </label>
                          <input id="discount" name="discount" type="text" onChange={(e)=>this.setState({discount_price:e.target.value})} className="form-control validate" required=""/>
                        </div>
                  </div>
                  
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 mx-auto mb-4">
                <div className="tm-product-img-dummy mx-auto">
                  <i className="fas fa-cloud-upload-alt tm-upload-icon"/>
				  {
					  this.state.previewImage && <img className="preview" src={this.state.previewImage} width='100px' alt="Preview Product" />
				  }
				  
                </div>
                <div className="custom-file mt-3 mb-3">
                  <input id="fileInput" type="file" accept="image/*" onChange={this.selectFile.bind(this)}/>
                  <input type="button" className="btn btn-warning btn-block mx-auto" value="UPLOAD PRODUCT IMAGE" onClick="document.getElementById('fileInput').click();"/>
                </div>
              </div>
              <div className="col-md-3">
                <button type="button" onClick={this.saveProduct.bind(this)} className="btn btn-primary btn-block text-uppercase">Add Product Now</button>
			  </div>
            
            </div>
          </div>
        </div>
      </div>
            
        </form>
		</div>
      </Fragment>
    );
  }
}

//export default withRouter(NewProduct);
//export default connect(null, { createProduct })(NewProduct);

const mapStateToProps = (state) => {
	console.log("State here")
	console.log(state)
  return {
    categories: state.categories,
  };
};

export default connect(mapStateToProps, {
  retrieveCategories,
  createProduct
})(NewProduct);
