import React, { Component } from "react";
import { Redirect, Switch, Route, Link } from "react-router-dom";
import { withRouter } from "react-router";
//import "./Dashboard.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "./Dashboard2.css";
//import "./CustomScrollbar.min.css";
import "@progress/kendo-theme-material/dist/all.css";
import "hammerjs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from 'jquery';
import Master from "./pages/Master";
import Pos from "./pages/Pos";
import Shops from "./pages/Shops";
import Customers from "./pages/Customers";
import Products from "./pages/Products";
import Orders from "./pages/Orders";
import Offers from "./pages/Offers";
import Category from "./pages/Category";
import Settings from "./pages/Settings";
import Sliders from "./pages/Sliders";
import IndexDashboard from "./pages/IndexDashboard";
import NotFound from "./404";
import AllComponents from "./pages/AllComponents";
import ViewProduct from "./components/ViewProduct";
import TopBar from "./TopBar";

const menuitems123 = [];
const menuitems = [
 {id: 0, item: 'dashboard',url:'', name: "Dashboard"},
 {id: 1, item: 'master',url:'master', name: "Master"},
 {id: 2, item: 'pos',url:'pos', name: "Pos"},
 {id: 3, item: 'shops',url:'shops', name: "Shops"},
 {id: 4, item: 'customers',url:'customers', name: "Customers"},
 {id: 5, item: 'products',url:'products', name: "Products"},
 {id: 6, item: 'orders',url:'orders', name: "Orders"},
 {id: 7, item: 'offers',url:'offers', name: "Offers"},
 {id: 8, item: 'category',url:'category', name: "Category"},
 {id: 9, item: 'sliders',url:'sliders', name: "Sliders"},
 {id: 10, item: 'settings',url:'settings', name: "Settings"},
];

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      islogout: false,
	  active:null
    };
  }
  componentDidMount() {
    // Jquery here
   $(document).ready(function () {
            /*$("#sidebar").mCustomScrollbar({
                theme: "minimal"
            });*/

            $('#sidebarCollapse').on('click', function () {
                $('#sidebar, #content').toggleClass('active');
                $('.collapse.in').toggleClass('in');
                $('a[aria-expanded=true]').attr('aria-expanded', 'false');
            });
        });

  }
  signOut = () => {
    localStorage.removeItem("token");
    this.setState({
      islogout: true,
	  active:null
    });
  };
  
  setActive(item){
	  console.log(item)
	  this.setState({active:item})
  }
  render() {
    if (this.state.islogout) {
      return <Redirect to="/login" />;
    }
    const { match } = this.props;
	//console.log(match)
    return (
	<div className="wrapper">
	
        <nav id="sidebar">
		<div className="profile">
                <img src="../logo2.png" className="img-responsive" alt="Indiancitymarket" width="100"/>
                <h3>ICM</h3>
                <p>Shashi Mishra</p>
            </div>

            <ul className="list-unstyled components">
		  {menuitems.map((item) => {
          return (
            <li
              key={item.id}
              onClick={() => this.setActive(item)}
              className={`list-group-item ${this.state.active == item && 'active'}`}
            >
              <Link to={`${match.path}/${item.url}`}>{item.name}</Link>
            </li>
          )
        })}
          
		  <li>
            <button className="btn btn-danger" onClick={this.signOut.bind(this)}>
              Sign Out
            </button>
          </li>
            </ul>

        </nav>
		
		<TopBar/>

        <div id="content">

            

            <Switch>
              <Route path={`${match.path}/master`}>
                <Master />
              </Route>
              <Route path={`${match.path}/pos`}>
                <Pos />
              </Route>
			  <Route path={`${match.path}/shops`}>
                <Shops />
              </Route>
			  <Route path={`${match.path}/customers`}>
                <Customers />
              </Route>
			  <Route path={`${match.path}/products`}>
                <Products />
              </Route>
			  <Route path={`${match.path}/product/:id`}>
                <ViewProduct />
              </Route>
			  <Route path={`${match.path}/orders`}>
                <Orders />
              </Route>
			  <Route path={`${match.path}/offers`}>
                <Offers />
              </Route>
			  <Route path={`${match.path}/category`}>
                <Category />
              </Route>
			  <Route path={`${match.path}/sliders`}>
                <Sliders />
              </Route>
			  <Route path={`${match.path}/settings`}>
                <Settings />
              </Route>
			  <Route path={`${match.path}/allcomponents`}>
                <AllComponents />
              </Route>
              <Route exact path={`${match.path}`}>
                <IndexDashboard />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
        </div>
    </div>
    );
  }
}

export default withRouter(Dashboard);
