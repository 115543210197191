import React from 'react';
import {
  Chart,
  ChartTitle,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
} from "@progress/kendo-react-charts";
import { Container, Row, Col } from "reactstrap";
import { COLORS } from "../constants";

// Graph data
const applicationsStatusThisMonth = [
  {
    status: "Accepted",
    value: 14,
    color: COLORS.accepted,
  },
  {
    status: "Confirmed",
    value: 14,
    color: COLORS.interviewing,
  },
  {
    status: "Rejected",
    value: 40,
    color: COLORS.rejected,
  },
  {
    status: "Pending",
    value: 32,
    color: COLORS.pending,
  },
];

// Show category label for each item in the donut graph
const labelContent = e => e.category;

const Donuts = props => {
  return (
  <Container>
  <Row>
  <Col>
  <Chart>
      <ChartTitle text="Applications status - this month" />
      <ChartLegend visible={false} />
      <ChartSeries>
        <ChartSeriesItem
          type="donut"
          data={applicationsStatusThisMonth}
          categoryField="status"
          field="value"
        >
          <ChartSeriesLabels
            color="#fff"
            background="none"
            content={labelContent}
          />
        </ChartSeriesItem>
      </ChartSeries>
    </Chart>
	</Col>
  <Col>second column</Col>
  </Row>
    
	</Container>
  );
};

export default Donuts;