import {
  CREATE_ORDER,
  RETRIEVE_ORDERS,
  UPDATE_ORDER,
  DELETE_ORDER,
  DELETE_ALL_ORDERS,
} from "../actions/types";

const initialState = [];

function orderReducer(orders = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_ORDER:
      return [...orders, payload];

    case RETRIEVE_ORDERS:
      return payload.data;

    case UPDATE_ORDER:
      return orders.map((order) => {
        if (order.id === payload.id) {
          return {
            ...order,
            ...payload,
          };
        } else {
          return order;
        }
      });

    case DELETE_ORDER:
      return orders.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_ORDERS:
      return [];

    default:
      return orders;
  }
};

export default orderReducer;