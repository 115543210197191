// Tutorial Types
export const CREATE_TUTORIAL = "CREATE_TUTORIAL";
export const RETRIEVE_TUTORIALS = "RETRIEVE_TUTORIALS";
export const UPDATE_TUTORIAL = "UPDATE_TUTORIAL";
export const DELETE_TUTORIAL = "DELETE_TUTORIAL";
export const DELETE_ALL_TUTORIALS = "DELETE_ALL_TUTORIALS";
// Product Types
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const RETRIEVE_PRODUCTS = "RETRIEVE_PRODUCTS";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_ALL_PRODUCTS = "DELETE_ALL_PRODUCTS";
// Order Types
export const CREATE_ORDER = "CREATE_ORDER";
export const RETRIEVE_ORDERS = "RETRIEVE_ORDERS";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";
export const DELETE_ALL_ORDERS = "DELETE_ALL_ORDERS";
// Shop Types
export const CREATE_SHOP = "CREATE_SHOP";
export const RETRIEVE_SHOPS = "RETRIEVE_SHOPS";
export const UPDATE_SHOP = "UPDATE_SHOP";
export const DELETE_SHOP = "DELETE_SHOP";
export const DELETE_ALL_SHOPS = "DELETE_ALL_SHOPS";
// Customers Types
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const RETRIEVE_CUSTOMERS = "RETRIEVE_CUSTOMERS";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const DELETE_ALL_CUSTOMERS = "DELETE_ALL_CUSTOMERS";
// Offer Types
export const CREATE_OFFER = "CREATE_OFFER";
export const RETRIEVE_OFFERS = "RETRIEVE_OFFERS";
export const UPDATE_OFFER = "UPDATE_OFFER";
export const DELETE_OFFER = "DELETE_OFFER";
export const DELETE_ALL_OFFERS = "DELETE_ALL_OFFERS";
// Category Types
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const RETRIEVE_CATEGORIES = "RETRIEVE_CATEGORIES";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_ALL_CATEGORIES = "DELETE_ALL_CATEGORIES";
// Slider Types
export const CREATE_SLIDER = "CREATE_SLIDER";
export const RETRIEVE_SLIDERS = "RETRIEVE_SLIDERS";
export const UPDATE_SLIDER = "UPDATE_SLIDER";
export const DELETE_SLIDER = "DELETE_SLIDER";
export const DELETE_ALL_SLIDERS = "DELETE_ALL_SLIDERS";
// Loader Types
export const START_TOGGLE_LOADING = "START_TOGGLE_LOADING";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";