import http from "../http-common";

class SliderDataService {
  getAll() {
    return http.get("/sliders");
  }

  get(id) {
    return http.get(`/sliders/${id}`);
  }

  create(data) {
    return http.post("/sliders", data);
  }

  update(id, data) {
    return http.put(`/sliders/${id}`, data);
  }

  delete(id) {
    return http.delete(`/sliders/${id}`);
  }

  deleteAll() {
    return http.delete(`/sliders`);
  }

  findByTitle(title) {
    return http.get(`/sliders?title=${title}`);
  }
}

export default new SliderDataService();