const tableCustomStyles = {
  headCells: {
    style: {
      fontSize: '12px',
      fontWeight: 'bold',
      paddingLeft: '0 2px',
      justifyContent: 'center',
      backgroundColor: '#f1c40f'
    },
  },
}
export { tableCustomStyles };