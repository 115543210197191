import {
  CREATE_ORDER,
  RETRIEVE_ORDERS,
  UPDATE_ORDER,
  DELETE_ORDER,
  DELETE_ALL_ORDERS
} from "./types";

import OrderDataService from "../services/order.service";

export const createOrder = (title, description) => async (dispatch) => {
  try {
    const res = await OrderDataService.create({ title, description });
	
    dispatch({
      type: CREATE_ORDER,
      payload: res.data,
    });

    return Promise.resolve(res.data.orders);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const retrieveOrders = () => async (dispatch) => {
	console.log("Testing Fetching data")
  try {
    const res = await OrderDataService.getAll();
	//console.log(res.data)
	//console.log(res.data.products)

    dispatch({
      type: RETRIEVE_ORDERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateOrder = (id, data) => async (dispatch) => {
  try {
    const res = await OrderDataService.update(id, data);

    dispatch({
      type: UPDATE_ORDER,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteOrder = (id) => async (dispatch) => {
  try {
    await OrderDataService.delete(id);

    dispatch({
      type: DELETE_ORDER,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllOrders = () => async (dispatch) => {
  try {
    const res = await OrderDataService.deleteAll();

    dispatch({
      type: DELETE_ALL_ORDERS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findOrdersByTitle = (title) => async (dispatch) => {
  try {
    const res = await OrderDataService.findByTitle(title);
    dispatch({
      type: RETRIEVE_ORDERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};