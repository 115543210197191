import React, { Component, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import DataTable from 'react-data-table-component';
import NewCategory from "../components/NewCategory";
import ListCategory from "../components/ListCategory";
import NotFound from "../404";
import { withRouter } from "react-router";



class Category extends Component {
	constructor(props){
		super(props);
		this.state = {
			isLoading:false,
		};
	}
	componentWillMount(){
	}
  render() {
	  const { match } = this.props;
    return (
      <Fragment>
        <h2>Category</h2>
		<Switch>
            <Route path={`${match.path}/new`} component={NewCategory} />
            <Route exact path={`${match.path}`} component={ListCategory} />
            <Route path="*" component={NotFound} />
          </Switch>
		</Fragment>
    );
  }
}
//export default Category;
export default withRouter(Category);