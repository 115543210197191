import {
  CREATE_SLIDER,
  RETRIEVE_SLIDERS,
  UPDATE_SLIDER,
  DELETE_SLIDER,
  DELETE_ALL_SLIDERS
} from "./types";

import SliderDataService from "../services/slider.service";

export const createSlider = (slider) => async (dispatch) => {
  try {
    const res = await SliderDataService.create({ slider });
    dispatch({
      type: CREATE_SLIDER,
      payload: res.data,
    });

    return Promise.resolve(res.data.sliders);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const retrieveSliders = () => async (dispatch) => {
	console.log("Testing Fetching data")
  try {
    const res = await SliderDataService.getAll();
	console.log(res.data.data)

    dispatch({
      type: RETRIEVE_SLIDERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateSlider = (id, data) => async (dispatch) => {
  try {
    const res = await SliderDataService.update(id, data);

    dispatch({
      type: UPDATE_PRODUCT,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteSlider = (id) => async (dispatch) => {
	console.log("Delete Slider" + id)
  try {
    await SliderDataService.delete(id);
	//await ProductDataService.delete(500);

    dispatch({
      type: DELETE_SLIDER,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllSliders = () => async (dispatch) => {
  try {
    const res = await SliderDataService.deleteAll();

    dispatch({
      type: DELETE_ALL_SLIDERS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findSlidersByTitle = (slider) => async (dispatch) => {
  try {
    const res = await SliderDataService.findByTitle(slider);
    dispatch({
      type: RETRIEVE_SLIDERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};