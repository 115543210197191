import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { connect } from "react-redux";
import {useDisptach} from 'react-redux'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
//import "../spinner.css";
//import {Modal} from '@material-ui/core';
import Loader from '../Loader';
import ModalImage from "react-modal-image";
//import { AiFillDelete } from "react-icons/bs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRemove } from '@fortawesome/free-solid-svg-icons'
import { tableCustomStyles } from '../tableStyle';
/*import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { connect } from "react-redux";
import {useDisptach} from 'react-redux'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
//import "../spinner.css";
import {Modal} from '@material-ui/core';
import Loader from '../Loader';
import ModalImage from "react-modal-image";*/
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
const imageMimeType = /image\/(png|jpg|jpeg)/i;

import {
  retrieveSliders,
  findSlidersByTitle,
  deleteSlider,
  deleteAllSliders,
} from "../actions/sliders"



function btnDelete(e, id){
      const res = deleteSlider(id)
	  console.log(res)
	  // Simple DELETE request with axios
    axios.delete('https://indiancitymarket.com/api/sliders/'+id)
        .then((res) => {toast(res.data.message);console.log(res)})
}
function changeHandler(e){
alert('eee' + e.target.src)
modal: !this.state.modal
}

const columns = [
  {
    name: 'ID',
    selector: (row, index) => index + 1,
    sortable: true,
  },
    {
    name: 'Photo',
    selector: row=><img src={`https://indiancitymarket.com/public/images/${row.slider}`} width="40" height="40" onClick={changeHandler}/>,
    sortable: true,
  },
  {
    name: 'Action',
      cell: row => <Button color="danger" size="sm" id={row.pid} onClick={(e)=>btnDelete(e,row.pid)}><FontAwesomeIcon icon={faRemove} /></Button>,
      selector: row => row,
      width: '100px'
  },
];

class ListSlider extends Component {
	constructor(props){
		super(props);
		this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
		this.refreshData = this.refreshData.bind(this);
		this.setActiveSlider = this.setActiveSlider.bind(this);
		this.findByTitle = this.findByTitle.bind(this);
		this.removeAllSliders = this.removeAllSliders.bind(this);
		//this.changeHandler = this.changeHandler.bind(this);
		
		this.state = {
			isLoading:false,
			showModal:false,
			selectedImage:"",
      currentTutorial: null,
      currentIndex: -1,
      searchTitle: "",
	  isLoading:false,
	  urlToTinyImageFil:'',
	  modal: false
    };
		
	}
	componentWillMount(){
		console.log('Testing showing data');
		//this.setState({isLoading:true})
		this.props.retrieveSliders();
		//const prods = this.props.retrieveSliders();
		/*const prod_promice = new Promise(function(resolve, reject) {
		
		  resolve(prods);
		});
		
		prod_promice.then(value => {
			console.log("promise resolved to show products")
			this.setState({isLoading:false})
		  console.log(value);
		});*/		

	}
	  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }
	onChangeSearchTitle(e) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle,
    });
  }
  onPreview(){
	  alert('open image modal')
  }

  refreshData() {
    this.setState({
      currentTutorial: null,
      currentIndex: -1,
    });
  }

  setActiveSlider(slider, index) {
    this.setState({
      currentSlider: slider,
      currentIndex: index,
    });
  }

  removeAllSliders() {
	  alert("All Slider Delete")
    this.props
      .deleteAllSliders()
      .then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  findByTitle() {
    this.refreshData();

    this.props.findSlidersByTitle(this.state.searchTitle);
  }
  handleCloseModal(){
	  
  }
  render() {
    const { match } = this.props;
	const { sliders } = this.props;
	console.log(sliders)
    return (
      <Fragment>
        <Button size="sm">
          <Link to={`${match.path}/new`}>New Slider</Link>
        </Button>
		<ToastContainer />
        <h3>List Slider</h3>
		<DataTable
        title="Sliders"
		primaryKey="id"
		customStyles={tableCustomStyles}
        columns={columns}
        data={sliders}
        highlightOnHover
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 15, 25, 50,100,500]}
        paginationComponentOptions={{
          rowsPerPageText: 'Records per page:',
          rangeSeparatorText: 'out of',
        }}
      />
	  <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
          <ModalBody>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

//export default ListSlider;
const mapStateToProps = (state) => {
	console.log("Show Sliders")
	console.log(state.sliders)
  return {
    sliders: state.sliders,
  };
};

export default connect(mapStateToProps, {
  retrieveSliders,
  findSlidersByTitle,
  deleteSlider,
  deleteAllSliders,
})(ListSlider);
