import React, { Component, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import DataTable from 'react-data-table-component';
import NewOffer from "../components/NewOffer";
import ListOffer from "../components/ListOffer";
import NotFound from "../404";
import { withRouter } from "react-router";



class Offers extends Component {
	constructor(props){
		super(props);
		this.state = {
			isLoading:false,
		};
		
	}
	componentWillMount(){
		console.log('Testing on load');
		/*fetch('https://indiancitymarket.com/api/products')
		.then(res =>res.json())
		.then(data=>this.setState({products:data.products}))*/
	}
  render() {
	  const { match } = this.props;
    return (
      <Fragment>

        <h2>Offers</h2>
		<Switch>
            <Route path={`${match.path}/new`} component={NewOffer} />
            <Route exact path={`${match.path}`} component={ListOffer} />
            <Route path="*" component={NotFound} />
          </Switch>

		
		</Fragment>
    );
  }
}
//export default Products;
export default withRouter(Offers);