import React, { Component, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import DataTable from 'react-data-table-component';
import NewProduct from "../components/NewProduct";
import ListProduct from "../components/ListProduct";
import {isfetching} from "../actions/isfetching";
import NotFound from "../404";
import { withRouter } from "react-router";
//import MySpinner from '../MySpinner';
import Loader from '../Loader';
//import Spinner from 'react-bootstrap/Spinner';



class Products extends Component {
	constructor(props){
		super(props);
		this.state = {
			isLoading:false,
		};
		
	}
	componentWillMount(){
		//console.log('Testing on load');
		/*fetch('https://indiancitymarket.com/api/products')
		.then(res =>res.json())
		.then(data=>this.setState({products:data.products}))*/
	}
  render() {
	  /*const myloader = this.state.isLoading;
	  let loader
	  if(myloader){
		  loader = <Loader/>;
	  }else{
		  loader = '';
	  }*/
	  const { match } = this.props;
    return (
      <Fragment>
        <h2>Products</h2>
		<Switch>
            <Route path={`${match.path}/new`} component={NewProduct} />
            <Route exact path={`${match.path}`} component={ListProduct} />
            <Route path="*" component={NotFound} />
          </Switch>

		
		</Fragment>
    );
  }
}
//export default Products;
export default withRouter(Products);