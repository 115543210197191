import React from "react";
import "./styles.css";
import Login from "./Login";
import Dashboard from "./Dashboard";
import Products from "./pages/Products";
import Footer from "./Footer";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <ProtectedRoute path="/dashboard">
          <Dashboard />
        </ProtectedRoute>
		<ProtectedRoute path="/products">
          <Products />
        </ProtectedRoute>
		
        <Route exact path="/">
          <Redirect exact from="/" to="dashboard" />
        </Route>
        <Route path="*">
          <Redirect from="/" to="dashboard" />
        </Route>
      </Switch>
      <div>
        <Footer/>
      </div>
    </Router>
  );
}
