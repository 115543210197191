import {
  CREATE_CUSTOMER,
  RETRIEVE_CUSTOMERS,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
  DELETE_ALL_CUSTOMERS
} from "./types";

import CustomerDataService from "../services/customer.service";

export const createCustomer = (title, description) => async (dispatch) => {
  try {
    const res = await CustomerDataService.create({ title, description });
	
    dispatch({
      type: CREATE_CUSTOMER,
      payload: res.data,
    });

    return Promise.resolve(res.data.customers);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const retrieveCustomers = () => async (dispatch) => {
  try {
    const res = await CustomerDataService.getAll();
	//console.log("Shops all")
	//console.log(res)
    dispatch({
      type: RETRIEVE_CUSTOMERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateCustomer = (id, data) => async (dispatch) => {
  try {
    const res = await CustomerDataService.update(id, data);

    dispatch({
      type: UPDATE_CUSTOMER,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteCustomer = (id) => async (dispatch) => {
  try {
    await CustomerDataService.delete(id);

    dispatch({
      type: DELETE_CUSTOMER,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllCustomers = () => async (dispatch) => {
  try {
    const res = await CustomerDataService.deleteAll();

    dispatch({
      type: DELETE_ALL_CUSTOMERS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findCustomersByTitle = (name) => async (dispatch) => {
	console.log('search customer' + name)
  try {
    const res = await CustomerDataService.findByTitle(name);
	console.log(res)
    dispatch({
      type: RETRIEVE_CUSTOMERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};