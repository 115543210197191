import {
  CREATE_OFFER,
  RETRIEVE_OFFERS,
  UPDATE_OFFER,
  DELETE_OFFER,
  DELETE_ALL_OFFERS
} from "./types";

import OfferDataService from "../services/offer.service";

export const createOffer = (product_id,name, amount,expired_at) => async (dispatch) => {
  try {
    const res = await OfferDataService.create({ product_id,name, amount,expired_at });
	
    dispatch({
      type: CREATE_OFFER,
      payload: res.data,
    });

    return Promise.resolve(res.data.offers);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const retrieveOffers = () => async (dispatch) => {
	//console.log("Testing Fetching data")
  try {
    const res = await OfferDataService.getAll();
	console.log("Fetching all offers")
	//console.log(res)
	console.log(res.data)

    dispatch({
      type: RETRIEVE_OFFERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateOffer = (id, data) => async (dispatch) => {
  try {
    const res = await OfferDataService.update(id, data);

    dispatch({
      type: UPDATE_OFFER,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteOffer = (id) => async (dispatch) => {
  try {
    await OfferDataService.delete(id);

    dispatch({
      type: DELETE_OFFER,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllOffers = () => async (dispatch) => {
  try {
    const res = await OfferDataService.deleteAll();

    dispatch({
      type: DELETE_ALL_OFFERS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findOffersByTitle = (name) => async (dispatch) => {
	console.log('search by name: ' + name)
  try {
    const res = await OfferDataService.findByTitle(name);
	console.log(res)
    dispatch({
      type: RETRIEVE_OFFERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};