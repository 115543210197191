import React, { Component } from "react";
import { withRouter } from "react-router";
import FileUploadComponent from './fileUpload.component';
import UploadImages from "./image-upload.component";
import { Button } from 'reactstrap';

class NewSlider extends Component {
  render() {
    return (
      <div>
        <Button size="sm"
          onClick={() => {
            this.props.history.goBack();
          }}
        >
          Back
        </Button>
        <h3>Upload New Slider</h3>
		<UploadImages />
      </div>
    );
  }
}

export default withRouter(NewSlider);
