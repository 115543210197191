import {
  CREATE_SLIDER,
  RETRIEVE_SLIDERS,
  UPDATE_SLIDER,
  DELETE_SLIDER,
  DELETE_ALL_SLIDERS,
} from "../actions/types";

const initialState = [];

function sliderReducer(sliders = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_SLIDER:
      return [...sliders, payload];

    case RETRIEVE_SLIDERS:
      return payload.data;

    case UPDATE_SLIDER:
      return sliders.map((slider) => {
        if (slider.id === payload.id) {
          return {
            ...slider,
            ...payload,
          };
        } else {
          return slider;
        }
      });

    case DELETE_SLIDER:
      return sliders.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_SLIDERS:
      return [];

    default:
      return sliders;
  }
};

export default sliderReducer;