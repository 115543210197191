import React, { Component, Fragment } from "react";
import './Footer.css';

class Footer extends Component {
  render() {
    //const { match } = this.props;
    return (
      <Fragment>
        <div className="footer">
  <p>Indiancitymarket</p>
</div>
      </Fragment>
    );
  }
}

export default Footer;
