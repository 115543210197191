import {
  CREATE_PRODUCT,
  RETRIEVE_PRODUCTS,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  DELETE_ALL_PRODUCTS,
  SHOW_LOADER,
  HIDE_LOADER,
} from "./types";

import ProductDataService from "../services/product.service";

export const createProduct = (user_id,category_id,title,unit_qty,media_type,price,original_price,discount_price,description,is_stock) => async (dispatch) => {
	//console.log("Add New Product:" + user_id,category_id,title,unit_qty,media_type,price,original_price,discount_price,description,is_stock)
     //dispatch({ type: START_TOGGLE_LOADING });

  try {
    const res = await ProductDataService.create({ user_id,category_id,title,unit_qty,media_type,price,original_price,discount_price,description,is_stock });
    dispatch({
      type: CREATE_PRODUCT,
      payload: res.data,
    });

    return Promise.resolve(res.data.products);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const retrieveProducts = () => async (dispatch) => {
	console.log("Testing Fetching data")
  try {
    const res = await ProductDataService.getAll();
	//console.log(res.data)
	//console.log(res.data.products)

    dispatch({
      type: RETRIEVE_PRODUCTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateProduct = (id, data) => async (dispatch) => {
  try {
    const res = await ProductDataService.update(id, data);

    dispatch({
      type: UPDATE_PRODUCT,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteProduct = (id) => async (dispatch) => {
	console.log("Delete Product" + id)
  try {
    await ProductDataService.delete(id);
	//await ProductDataService.delete(500);

    dispatch({
      type: DELETE_PRODUCT,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllProducts = () => async (dispatch) => {
  try {
    const res = await ProductDataService.deleteAll();
	//const res = await ProductDataService.delete(501);

    dispatch({
      type: DELETE_ALL_PRODUCTS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findProductsByTitle = (title) => async (dispatch) => {
  try {
    const res = await ProductDataService.findByTitle(title);
    dispatch({
      type: RETRIEVE_PRODUCTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const showLoader = () => (dispatch) => {
	console.log('Show loader now')
      dispatch({
        type: SHOW_LOADER,
      });
    };

export const hideLoader = () => (dispatch) => {
      dispatch({
        type: HIDE_LOADER,
      });
    };