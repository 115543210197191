import http from "../http-common";

class CustomerDataService {
  getAll() {
    return http.get("/users?role=3");
  }

  get(id) {
    return http.get(`/users/${id}`);
  }

  create(data) {
    return http.post("/users", data);
  }

  update(id, data) {
    return http.put(`/users/${id}`, data);
  }

  delete(id) {
    return http.delete(`/users/${id}`);
  }

  deleteAll() {
    return http.delete(`/users`);
  }

  findByTitle(name) {
    return http.get(`/users?name=${name}`);
  }
}

export default new CustomerDataService();