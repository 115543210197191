import {
  CREATE_OFFER,
  RETRIEVE_OFFERS,
  UPDATE_OFFER,
  DELETE_OFFER,
  DELETE_ALL_OFFERS,
} from "../actions/types";

const initialState = [];

function offerReducer(offers = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_OFFER:
      return [...offers, payload];

    case RETRIEVE_OFFERS:
      return payload.data;

    case UPDATE_OFFER:
      return offers.map((offer) => {
        if (offer.id === payload.id) {
          return {
            ...offer,
            ...payload,
          };
        } else {
          return offer;
        }
      });

    case DELETE_OFFER:
      return offers.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_OFFERS:
      return [];

    default:
      return offers;
  }
};

export default offerReducer;