import React from 'react'
//import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './spinner.css';

class Loader extends React.Component {
  render(){
	return (
    <div className="spinner-container">
      	  <div className="loader"></div>
        </div>
  );  
  }
}

export default Loader;
