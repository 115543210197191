import React, { Component } from "react";
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
//import "./Login.css";
import { Redirect } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './Loader';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      islogged: false,
	  isLoading:false,
	  userid:"",
      loginParams: {
        user_id: "",
        user_password: ""
      }
    };
  }
  handleFormChange = event => {
    let loginParamsNew = { ...this.state.loginParams };
    let val = event.target.value;
    loginParamsNew[event.target.name] = val;
    this.setState({
      loginParams: loginParamsNew
    });
  };

  login = event => {
	  event.preventDefault();
    let user_id = this.state.loginParams.user_id;
    let user_password = this.state.loginParams.user_password;
	console.log(user_id + user_password)
    /*if (user_id === "admin" && user_password === "123456") {
      localStorage.setItem("token", "T");
      this.setState({
        islogged: true
      });
    }*/
	this.setState({
        islogged: true
      });
	// POST request using axios with error handling
    const user = { email: user_id, password: user_password };
    axios.post('https://indiancitymarket.com/api/adminlogin', user)
        .then(response => {console.log(response); if(response.data.status){this.setState({islogged: true});toast(response.data.message);localStorage.setItem("token", "T");this.setState({ islogged: true,userid:response.data.id })}else{toast(response.data.message);} })
        .catch(error => {
            //this.setState({ errorMessage: error.message });
            console.error('There was an error!', error);
        });
    
  };
  render() {
	  const myloader = this.state.isLoading;
	  let loader
	  if(myloader){
		  loader = <Loader/>;
	  }else{
		  loader = '';
	  }
    if (localStorage.getItem("token")) {
      return <Redirect to="/" />;
    }
    return (
      <div className="container">
	  {loader}
	  <ToastContainer />
	  <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
	  <div className="row">
	  <div className="col-md-6">
	  <img src="icm.png" width="100px" className="img img-responsive" alt="Indiancitymarket"/>
	  <Form onSubmit={this.login} className="form-signin1" autocomplete="off">
        <FormGroup>
          <Label for="user_id">Username</Label>
          <Input type="text" name="user_id" id="user_id" onChange={this.handleFormChange}
                placeholder="Enter Username" />
        </FormGroup>
        <FormGroup>
          <Label for="examplePassword">Password</Label>
          <Input type="password"
                name="user_password"
                onChange={this.handleFormChange}
                placeholder="Enter Password" />
        </FormGroup>
       
       
        <Button color="primary" type="submit">Submit</Button>
      </Form>
	  </div>
	  <div className="col-md-6">
	  <img src="../login_home.jpg" width="600"/>
	  </div>
	  </div>
      </div>
    );
  }
}
export default Login;
