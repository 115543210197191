import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import DataTable from 'react-data-table-component';
import { Container, Row, Col } from "reactstrap";
//import Counter from "./Counter";
//import { AddUser,GetUsers,incrementCount } from "./actions/taskAction";
import Footer from '../Footer';
//import Testing from './Testing';
import Donuts from "../charts/Donuts";
import Bar from "../charts/Bar";
import Line from "../charts/Line";
//import Chart from "../charts/Chart";

const columns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Phone',
    selector: 'phone',
    sortable: true,
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
  },
  {
    name: 'DOB',
    selector: 'dob',
  },
];

class Pos extends Component {
	constructor(props) {
      super(props);
	  this.state = {
		  Users:[],
		  persons:[]
	  };
    }
	componentWillMount(){
		fetch('https://jsonplaceholder.typicode.com/users')
		.then(res=>res.json())
		.then(data=>this.setState({persons:data}))
	}
	 // creating new user using Redux-Thunk
    addNewUser = () => {
        let data = {
            "name": "Laxman Rana",
            "job": "developer"
        }
	//return { type: AddUser, payload: data }
        this.props.submit(
            data
        );
    }
	incrementCount = () => {
        
    }
  render() {
	  // getting users from redux store
        //console.log("this.props.Users : " + this.props.Users);
		//console.log("this.state.Users : " + this.state.Users);
		console.log(global.config.i18n.welcome.en)
		//dispatch({ type: 'GetUsers' })
		
    return (
      <Fragment>
	  <Container>
        <h2>Point Of Sale</h2>

		<p>Show data here</p>
		<Row>
		<Col><Donuts/></Col><Col><Bar/></Col>
		</Row>
		<Line/>
		
		
      
		<Footer/>
		</Container>
      </Fragment>
    );
  }
}
export default Pos;
/*const mapStateToProps = state => ({
    Users: state.GET_USERS
});*/

/*const mapDispacthToProps = dispatch => {
	//incrementCount: () => dispatch(incrementCount())
	//console.log(dispatch)
    return {
        submit: (data) => {
		    //dispatch(AddUser(data))
			return {
					type: 'AddUser',data
				}
        }
    };
};*/
// export default withStyles(styles)(ProductList);
//export default connect(mapStateToProps,mapDispacthToProps)(Pos);
