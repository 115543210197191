import React, { Component, Fragment } from "react";
import '../Dashboard.css';
import { Row,Col,Container,Card,CardBody,CardText,CardTitle,CardSubtitle,Button, Form, FormGroup, Label, Input, FormText,Carousel,
  CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption, } from 'reactstrap';
//import  * as All from 'reactstrap';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom'
//const {Link, BrowserRouter} = window.ReactRouterDOM
import history from './../history';

const items = [
  {
    src: 'https://picsum.photos/id/123/1200/400',
    altText: 'Slide 1',
    caption: 'Slide 1',
    key: 1,
  },
  {
    src: 'https://picsum.photos/id/456/1200/400',
    altText: 'Slide 2',
    caption: 'Slide 2',
    key: 2,
  },
  {
    src: 'https://picsum.photos/id/678/1200/400',
    altText: 'Slide 3',
    caption: 'Slide 3',
    key: 3,
  },
];

class IndexDashboard extends Component {
  render() {
    return (
      <Fragment>
        <h2>DashBoard: Indiancitymarket</h2>
		<Container>
  <Row>
    <Col md={3}>
    <Card
  style={{
    width: '18rem'
  }}
>
  <img className="img-responsive icon"
    alt="Sample"
    src="./shops_icon.png"
  />
  <CardBody>
    <CardTitle tag="h5">
      Shops
    </CardTitle>
    <CardText>
      
    </CardText>
      <Link className="btn btn-primary" to='/dashboard/shops'> Go  </Link>
  </CardBody>
</Card>
    </Col>
	<Col md={3}>
      <Card
  style={{
    width: '18rem'
  }}
>
  <img className="img-responsive icon"
    alt="Sample"
    src="../customer_icon.jpg"
  />
  <CardBody>
    <CardTitle tag="h5">
      Customers
    </CardTitle>
    <CardText>
      
    </CardText>

      <Link className="btn btn-primary" to='/dashboard/customers'> Go  </Link>
  </CardBody>
</Card>
    </Col>
	<Col md={3}>
    <Card
  style={{
    width: '18rem'
  }}
>
  <img className="img-responsive icon"
    alt="Sample"
    src="../order_icon.jpg"
  />
  <CardBody>
    <CardTitle tag="h5">
Orders
    </CardTitle>
    <CardText>
      
    </CardText>

      <Link className="btn btn-primary" to='/dashboard/orders'> Go  </Link>

  </CardBody>
</Card>
    </Col>
	<Col md={3}>
    <Card
  style={{
    width: '18rem'
  }}
>
  <img className="img-responsive icon"
    alt="Sample"
    src="../offer_icon.png"
  />
  <CardBody>
    <CardTitle tag="h5">
      Offers
    </CardTitle>
    <CardText>
      
    </CardText>

      <Link className="btn btn-primary" to='/dashboard/offers'> Go  </Link>

  </CardBody>
</Card>
    </Col>
  </Row>
  <Row>
  <Col md={12}>
  
  </Col>
  </Row>
    <Row>
  <Col md={12}>
<div className="card">
      <img className="img-responsive" src="./logo.png" alt="Indiacntiymarekt"/>

      <div className="card_details">


        <div className="name">Shops</div>

        <p>Indiancitymarket</p>

        <button>Read more</button>
      </div>

	</div>
  </Col>
  </Row>
  </Container>
		
      </Fragment>
    );
  }
}
export default withRouter(IndexDashboard);
