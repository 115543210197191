import React, { Component, Fragment } from "react";
import DataTable from 'react-data-table-component';
import { connect } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'reactstrap';
import getSymbolFromCurrency from 'currency-symbol-map'
import { tableCustomStyles } from '../tableStyle';

import {
  retrieveOrders,
  findOrdersByTitle,
  deleteAllOrders,
} from "../actions/orders"

const columns = [
  {
    name: 'ID',
    selector: (row, index) => index + 1,
    sortable: true,
  },
  {
    name: 'Name',
    selector: row=>row.name,
    sortable: true,
  },
  ,
  {
    name: 'Phone',
    selector: row=>row.phone,
    sortable: true,
  },
  {
    name: 'Product',
    selector: row=>row.title,
    sortable: true,
  },
  {
    name: 'Price',
    selector: row=>getSymbolFromCurrency('INR') + row.price,
    sortable: true,
  },
  {
    name: 'Qty',
    selector: row=>row.qty,
    sortable: true,
  },
  {
    name: 'Action',
      cell: row => <Button color="danger" id={row.oid} size="sm" onClick={(e)=>btnDelete(e,row.oid)}>Delete</Button>,
      selector: row => row,
      width: '100px'
  },
];

class Orders extends Component {
	constructor(props){
		super(props);
			super(props);
		this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
		this.refreshData = this.refreshData.bind(this);
		this.setActiveOrder = this.setActiveOrder.bind(this);
		this.findByTitle = this.findByTitle.bind(this);
		this.removeAllOrders = this.removeAllOrders.bind(this);
		
		this.state = {
      currentTutorial: null,
      currentIndex: -1,
      searchTitle: "",
    };
		
	}
	componentWillMount(){
		console.log('Testing on load');
		this.props.retrieveOrders();
		/*fetch('https://indiancitymarket.com/api/orders')
		.then(res =>res.json())
		.then(data=>this.setState({orders:data.orders}))*/
	}
	
		/*componentWillMount(){
		console.log('Testing showing data');
		//debugger;
		fetch('https://indiancitymarket.com/api/products')
		.then(res =>res.json())
		.then(data=>this.setState({products:data.products}))
	}*/
	onChangeSearchTitle(e) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle,
    });
  }

  refreshData() {
    this.setState({
      currentTutorial: null,
      currentIndex: -1,
    });
  }

  setActiveOrder(order, index) {
    this.setState({
      currentOrder: order,
      currentIndex: index,
    });
  }

  removeAllOrders() {
    this.props
      .deleteAllOrders()
      .then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  findByTitle() {
    this.refreshData();

    this.props.findOrdersByTitle(this.state.searchTitle);
  }
  render() {
	  const { orders } = this.props;
    return (
      <Fragment>
        <h2>Orders</h2>
		<div className="list row">
		<div className="list row">
		<div className="col-md-2">Exact Match:</div>
		<div className="col-md-1"><input className="checkbox" type="checkbox" /></div>
		</div>
        <div className="col-md-8">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search by name, phone or email"
              value={this.state.searchTitle}
              onChange={this.onChangeSearchTitle}
            />
            <div className="input-group-append">
              <button
                className="btn btn-primary"
                type="button"
                onClick={this.findByTitle}
              >
			  Search
              </button>
			  
            </div>
          </div>
        </div>
		</div>
		<DataTable
        title="Orders"
		primaryKey="oid"
		customStyles={tableCustomStyles}
        columns={columns}
        data={orders}
        highlightOnHover
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 15, 25, 50,100,500,1000]}
        paginationComponentOptions={{
          rowsPerPageText: 'Records per page:',
          rangeSeparatorText: 'out of',
        }}
      />
		</Fragment>
    );
  }
}
//export default Orders;
const mapStateToProps = (state) => {
	console.log("Show Data here")
	console.log(state)
  return {
    orders: state.orders
  };
};

export default connect(mapStateToProps, {
  retrieveOrders,
  findOrdersByTitle,
  deleteAllOrders,
})(Orders);
