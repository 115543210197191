import React, { Component, Fragment } from "react";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { connect } from "react-redux";
//import {Button,TextField} from '@material-ui/core';
import { Button } from 'reactstrap';
import { tableCustomStyles } from '../tableStyle';

import {
  retrieveShops,
  findShopsByTitle,
  deleteAllShops,
} from "../actions/shops"

	function btnDelete(e){
		alert("Deleted" + e.target.id);
		const payload = {
			  id: e.target.id,
			}

		/*axios({
		  method: 'DELETE',
		  url: 'https://indiancitymarket.com/api/shops/' + e.target.id,
		  data: payload, // you are sending body instead
		  headers: {
		   // 'Authorization': `bearer ${token}`,
		  'Content-Type': 'application/json'
		  }, 
		}).then((response) => {
        console.log(response);
        })
    .catch((error) => {
       alert(error)
      })*/
	}

const columns = [
  {
    name: 'ID',
    selector: (row, index) => index + 1,
    sortable: true,
  },
  {
    name: 'Photo',
    selector: row=><img src={`https://indiancitymarket.com/public/images/${row.photo}`} width="40" height="40"/>,
    sortable: false,
  },
  {
    name: 'Name',
    selector: row=>row.name,
    sortable: true,
  },
  {
    name: 'Address',
    selector: row=>row.address,
    sortable: true,
  },
  {
    name: 'Phone',
    selector: row=>row.phone,
    sortable: true,
  },
  {
    name: 'Email',
    selector: row=>row.email,
    sortable: true,
  },
  {
    name: 'Action',
      cell: row => <Button color="danger" id={row.id} size="sm" onClick={(e)=>btnDelete(e,row.id)}>Delete</Button>,
      selector: row => row.shopid,
      width: '100px'
  },
];
class Shops extends Component {
		constructor(props){
		super(props);
		this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
		this.refreshData = this.refreshData.bind(this);
		this.setActiveShop = this.setActiveShop.bind(this);
		this.findByTitle = this.findByTitle.bind(this);
		this.removeAllShops = this.removeAllShops.bind(this);
		
		this.state = {
      currentShop: null,
      currentIndex: -1,
      searchTitle: "",
    };
		
	}
		componentWillMount(){
		this.props.retrieveShops();
	}
	
onChangeSearchTitle(e) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle,
    });
  }

  refreshData() {
    this.setState({
      currentShop: null,
      currentIndex: -1,
    });
  }

  setActiveShop(shop, index) {
    this.setState({
      currentShop: shop,
      currentIndex: index,
    });
  }

  removeAllShops() {
    this.props
      .deleteAllShops()
      .then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  findByTitle() {
    this.refreshData();

    this.props.findShopsByTitle(this.state.searchTitle);
  }

  render() {
	  const { shops } = this.props;
    return (
      <Fragment>
        <h2>Shops</h2>
		<DataTable
        title="Shops"
		primaryKey="shopid"
		customStyles={tableCustomStyles}
        columns={columns}
        data={shops}
        highlightOnHover
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 15, 25, 50,100,500,1000]}
        paginationComponentOptions={{
          rowsPerPageText: 'Records per page:',
          rangeSeparatorText: 'out of',
        }}
      />
		</Fragment>
    );
  }
}
//export default Shops;
const mapStateToProps = (state) => {
  return {
    shops: state.shops,
  };
};

export default connect(mapStateToProps, {
  retrieveShops,
  findShopsByTitle,
  deleteAllShops,
})(Shops);
