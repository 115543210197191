import { combineReducers } from "redux";
import products from "./products";
import orders from "./orders";
import shops from "./shops";
import customers from "./customers";
import offers from "./offers";
import categories from "./categories";
import sliders from "./sliders";

export default combineReducers({
  shops,
  customers,
  products,
  orders,
  offers,
  categories,
  sliders
});
