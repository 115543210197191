import {
  CREATE_SHOP,
  RETRIEVE_SHOPS,
  UPDATE_SHOP,
  DELETE_SHOP,
  DELETE_ALL_SHOPS
} from "./types";

import ShopDataService from "../services/shop.service";

export const createShop = (title, description) => async (dispatch) => {
  try {
    const res = await ShopDataService.create({ title, description });
	
    dispatch({
      type: CREATE_SHOP,
      payload: res.data,
    });

    return Promise.resolve(res.data.shops);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const retrieveShops = () => async (dispatch) => {
  try {
    const res = await ShopDataService.getAll();
	console.log("Shops all")
	console.log(res)

    dispatch({
      type: RETRIEVE_SHOPS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateShop = (id, data) => async (dispatch) => {
  try {
    const res = await ShopDataService.update(id, data);

    dispatch({
      type: UPDATE_SHOP,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteShop = (id) => async (dispatch) => {
  try {
    await ShopDataService.delete(id);

    dispatch({
      type: DELETE_SHOP,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllShops = () => async (dispatch) => {
  try {
    const res = await ShopDataService.deleteAll();

    dispatch({
      type: DELETE_ALL_SHOPS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findShopsByTitle = (title) => async (dispatch) => {
  try {
    const res = await ShopDataService.findByTitle(title);

    dispatch({
      type: RETRIEVE_SHOPS,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err);
  }
};