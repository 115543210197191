//config.js
module.exports = global.config = {
    i18n: {
        welcome: {
            en: "Welcome",
            fa: "Indiancitymarket"
        }
        // rest of your translation object
    }
    // other global config variables you wish
};