import React, { Component, Fragment } from "react";
//import Profile from './Profile';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

class Settings extends Component {
  render() {
    return (
      <Fragment>
        <h2>Settings</h2>
		<Form>
        <FormGroup>
          <Label for="exampleEmail">Email</Label>
          <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
        </FormGroup>
        <FormGroup>
          <Label for="examplePassword">Password</Label>
          <Input type="password" name="password" id="examplePassword" placeholder="password placeholder" />
        </FormGroup>
		
		<FormGroup>
          <Label for="examplePassword">Phone</Label>
          <Input type="password" name="password" id="phone" placeholder="Enter Phone" />
        </FormGroup>

        

        <Button>Submit</Button>
      </Form>
		</Fragment>
    );
  }
}
export default Settings;
