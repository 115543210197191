import React, { Component, Fragment } from "react";
import { Switch, Route, Link } from "react-router-dom";
//import 'bootstrap/dist/css/bootstrap.min.css';
import DataTable from 'react-data-table-component';
import { connect } from "react-redux";
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import NewProduct from "./NewProduct";
import ListProduct from "./ListProduct";
import NotFound from "../404";
import { withRouter } from "react-router";
import {useDisptach} from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
//import FileUploadComponent from './fileUpload.component';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createOffer } from "../actions/offers";
import {retrieveProducts} from "../actions/products"

import {
  retrieveOffers,
  findOffersByTitle,
  deleteOffer,
  deleteAllOffers,
} from "../actions/offers"

function btnDelete(e, id){
	alert('Delete product' + id)
      deleteProduct(id)
      .then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });
	/*this.props
      .deleteProduct()
      .then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });*/
	  //var status1 = this.deleteProduct()
	  //console.log(status1)
	  //const dispatch = useDispatch()
	  //dispatch(deleteProduct(1010))
      /*.then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });*/
}

const columns = [
  {
    name: 'ID',
    selector: row=>row.product_id,
    sortable: true,
  },
  {
    name: 'Name',
    selector: row=>row.name,
    sortable: true,
  },
  {
    name: 'Amount',
    selector: row=>row.amount,
    sortable: true,
  },
  {
    name: 'Action',
      cell: row => <button className="btn-sm" id={row.id} onClick={(e)=>btnDelete(e,row.id)}>Delete</button>,
      selector: row => row,
      width: '100px'
  },
];

class NewOffer extends Component {
		constructor(props){
		super(props);
		this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
		this.refreshData = this.refreshData.bind(this);
		this.setActiveOffer = this.setActiveOffer.bind(this);
		this.findByTitle = this.findByTitle.bind(this);
		this.removeAllOffers = this.removeAllOffers.bind(this);
		//this.btnDelete = this.btnDelete.bind(this);
		//this.laxman = this.laxman.bind(this);
		
		this.state = {
			product_id:'',
			name:'',
			amount:'',
			expired_at:'',
			currentTutorial: null,
			currentIndex: -1,
			searchTitle: "",
    };
		
	}
		componentWillMount(){
		console.log('Testing showing data');
		this.props.retrieveOffers();
		this.props.retrieveProducts();
	
		//debugger;
		/*fetch('https://indiancitymarket.com/api/products')
		.then(res =>res.json())
		.then(data=>this.setState({products:data.products}))*/
	}
	onChangeSearchTitle(e) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle,
    });
  }

  refreshData() {
    this.setState({
      currentTutorial: null,
      currentIndex: -1,
    });
  }

  setActiveOffer(offer, index) {
    this.setState({
      currentOffer: offer,
      currentIndex: index,
    });
  }

  removeAllOffers() {
	  alert("All Product Delete")
    this.props
      .deleteAllOffers()
      .then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  findByTitle() {
    this.refreshData();

    this.props.findOffersByTitle(this.state.searchTitle);
  }
  productChange(e){
	  console.log(e.target.value)
	  this.setState({product_id:e.target.value})
  }
  categoryChange(e){
	  console.log(e.target.value)
	  this.setState({product_id:e.target.value})
  }
  addNewOffer(){
	  alert("form submit")
	  /*const totaldata = this.state;
	  console.log(totaldata)
    const { product_id,name,amount,expired_at} = this.state;
    this.props
      .createOffer(product_id,name,amount,expired_at)
      .then((data) => {
        console.log("Resposne from store");
		console.log(data);
		toast("Offer Created Successfully");
		this.setState({product_id: '',name:'',amount:''});
      })
      .catch((e) => {
        console.log(e);
      });*/
  }
  setDate(e){
	  console.log('date')
	  console.log(e.date)
	  this.setState({expired_at:e.date})
  }
  handleSubmit(event) {
    event.preventDefault()
    console.log(this.state)
	const { product_id,name,amount,expired_at} = this.state;
    this.props
      .createOffer(product_id,name,amount,expired_at)
      .then((data) => {
        console.log("Resposne from store");
		console.log(data);
		toast("Offer Created Successfully");
		this.setState({product_id: '',name:'',amount:'',expired_at:''});
      })
      .catch((e) => {
        console.log(e);
      });
  }
  render() {
	  const { match } = this.props;
	const { offers } = this.props;
	const { products } = this.props;
	console.log(products)
    return (
      <Fragment>
			  <Button size="sm"
          onClick={() => {
            this.props.history.goBack();
          }}
        >
          Back
        </Button>
		<ToastContainer/>
		
		
		
		<div className="container">		
		<Form onSubmit={this.handleSubmit.bind(this)}>
		<FormGroup>
          <Label for="exampleSelect">Select Product</Label>
          <Input type="select" name="select" id="exampleSelect" onChange={this.productChange.bind(this)}>
		  <option value="">select product</option>
            {
						products.map((prod,index)=>
						<option key={index} value={prod.pid}>{prod.title}</option>
						)
						}
          </Input>
		  
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail">Enter Title</Label>
          <Input type="text" name="email" id="exampleEmail" onChange={(e)=>this.setState({name:e.target.value})} placeholder="Enter offer name" value={this.state.name}/>
        </FormGroup>
        <FormGroup>
          <Label for="examplePassword">Amount</Label>
          <Input type="number" name="amount" id="amount" onChange={(e)=>this.setState({amount:e.target.value})} placeholder="Enter amount" value={this.state.amount}/>
        </FormGroup>
		<FormGroup>
          <Label for="examplePassword">Expired At</Label>
		  <Input type="text" name="email" id="exampleEmail" onChange={(e)=>this.setState({expired_at:e.target.value})} placeholder="enter expiry date" value={this.state.expired_at} />
		 
        </FormGroup>
        
        
        <Button color="primary" type="submit">Submit</Button>
      </Form>
	  </div>
		
		
		</Fragment>
    );
  }
}
//export default Offers;
const mapStateToProps = (state) => {
	console.log("Show Offers Data")
	console.log(state)
  return {
    offers: state.offers,
	products: state.products,
  };
};

export default connect(mapStateToProps, {
  retrieveOffers,
  createOffer,
  retrieveProducts,
  findOffersByTitle,
  deleteOffer,
  deleteAllOffers,
})(NewOffer);