import React, { Component, Fragment } from "react";
import { Switch, Route, Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { connect } from "react-redux";
import NewProduct from "./NewProduct";
import ListProduct from "./ListProduct";
import NotFound from "../404";
import { withRouter } from "react-router";
import {useDisptach} from 'react-redux'
import { Button } from 'reactstrap';
//import {retrieveProducts} from "../actions/products"
import getSymbolFromCurrency from 'currency-symbol-map'
import { tableCustomStyles } from '../tableStyle';

import {
  retrieveOffers,
  findOffersByTitle,
  deleteOffer,
  deleteAllOffers,
} from "../actions/offers"

function btnDelete(e, id){
	alert('Delete product' + id)
      deleteProduct(id)
      .then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });
	/*this.props
      .deleteProduct()
      .then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });*/
	  //var status1 = this.deleteProduct()
	  //console.log(status1)
	  //const dispatch = useDispatch()
	  //dispatch(deleteProduct(1010))
      /*.then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });*/
}

const columns = [
  {
    name: 'ID',
    selector: (row, index) => index + 1,
    sortable: true,
  },
  {
    name: 'Name',
    selector: row=>row.name,
    sortable: true,
  },
  {
    name: 'Amount',
    selector: row=>getSymbolFromCurrency('INR') + row.amount,
    sortable: true,
  },
  {
    name: 'Action',
      cell: row => <Button color="danger" id={row.id} size="sm" onClick={(e)=>btnDelete(e,row.id)}>Delete</Button>,
      selector: row => row,
      width: '100px'
  },
];

class ListOffer extends Component {
		constructor(props){
		super(props);
		this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
		this.refreshData = this.refreshData.bind(this);
		this.setActiveOffer = this.setActiveOffer.bind(this);
		this.findByTitle = this.findByTitle.bind(this);
		this.removeAllOffers = this.removeAllOffers.bind(this);
		
		this.state = {
      currentTutorial: null,
      currentIndex: -1,
      searchTitle: "",
    };
		
	}
		componentWillMount(){
		console.log('Testing showing data');
		this.props.retrieveOffers();
	}
	onChangeSearchTitle(e) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle,
    });
  }

  refreshData() {
    this.setState({
      currentTutorial: null,
      currentIndex: -1,
    });
  }

  setActiveOffer(offer, index) {
    this.setState({
      currentOffer: offer,
      currentIndex: index,
    });
  }

  removeAllOffers() {
	  alert("All Product Delete")
    this.props
      .deleteAllOffers()
      .then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  findByTitle() {
    this.refreshData();

    this.props.findOffersByTitle(this.state.searchTitle);
  }
  render() {
	  const { match } = this.props;
	const { offers } = this.props;

    return (
      <Fragment>
			  <Button size="sm" onClick={() => {
            this.props.history.goBack();
          }}
        >
          Back
        </Button>{' '}
		<Button color="warning" size="sm"><Link to={`${match.path}/new`}>New Offer</Link></Button>
		
		<div className="list row">
		<div className="col-md-2">Exact Match:</div>
		<div className="col-md-1"><input className="checkbox" type="checkbox" /></div>
		</div>
		<div className="list row">
        <div className="col-md-8">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search by title"
              value={this.state.searchTitle}
              onChange={this.onChangeSearchTitle}
            />
            <div className="input-group-append">
              <button
                className="btn btn-primary"
                type="button"
                onClick={this.findByTitle}
              >
			  Search
              </button>
            </div>
          </div>
        </div>
		</div>
		
		<DataTable
        title="Offers"
		primaryKey="id"
		customStyles={tableCustomStyles}
        columns={columns}
        data={offers}
        highlightOnHover
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 15, 25, 50,100,500]}
        paginationComponentOptions={{
          rowsPerPageText: 'Records per page:',
          rangeSeparatorText: 'out of',
        }}
      />
		</Fragment>
    );
  }
}
//export default Offers;
const mapStateToProps = (state) => {
	console.log("Show Offers Data")
  return {
    offers: state.offers,
  };
};

export default connect(mapStateToProps, {
  retrieveOffers,
  findOffersByTitle,
  deleteOffer,
  deleteAllOffers,
})(ListOffer);