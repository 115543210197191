import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from 'axios';
import { connect } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import { createCategory } from "../actions/categories";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'reactstrap';

class NewCategory extends React.Component {
	constructor(props){
	super(props);
	this.state = {
		name:""
	};
	}
	componentWillMount(){
		//code here
	}
	
	saveCategory(){
		//e.preventDefault()
		console.log('new category: ' + this.state.name)
		const totaldata = this.state;
	  console.log(totaldata)
    const { name } = this.state;
	console.log("Save category")
    this.props
      .createCategory(name)
      .then((data) => {
        console.log("Resposne from store");
		console.log(data);
		toast("Category Added Successfully");
      })
      .catch((e) => {
        console.log(e);
      });
	  createCategory(name)
      
	}
  render() {
    return (
      <div>
	  <Button color="secondary" size="sm" onClick={() => {
            this.props.history.goBack();
          }}>Back</Button>
        <h3>New Category</h3>
		<ToastContainer />
		<form>
		<div className="form-group">
		<label>Exact Match:</label>
		<input type="text" className="form-control" name="name" onChange={(e)=>this.setState({name:e.target.value})} placeholder="Enter category name" value={this.state.name}/>
		</div>
		<Button color="primary" onClick={this.saveCategory.bind(this)}>Save</Button>
		</form>
      </div>
    );
  }
}

//export default withRouter(NewCategory);
//export default connect(null, { createCategory })(NewCategory);

const mapStateToProps = (state) => {
	console.log("State here")
	console.log(state)
  return {
    categories: state.categories,
  };
};

export default connect(mapStateToProps, {
  createCategory
})(NewCategory);
