import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { connect } from "react-redux";
import {useDisptach} from 'react-redux'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';
import { Button } from 'reactstrap';
import { tableCustomStyles } from '../tableStyle';

import {
  retrieveCategories,
  findCategoriesByTitle,
  deleteCategory,
  deleteAllCategories,
} from "../actions/categories"


function btnDelete(e, id){
      const res = deleteCategory(id)
	  console.log(res)
	  // Simple DELETE request with axios
    axios.delete('https://indiancitymarket.com/api/products/'+id)
        .then((res) => {toast(res.data.message);console.log(res)})
	/*this.props
      .deleteProduct()
      .then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });*/
	  //var status1 = this.deleteProduct()
	  //console.log(status1)
	  //const dispatch = useDispatch()
	  //dispatch(deleteProduct(1010))
      /*.then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });*/
}


const columns = [
  {
    name: 'ID',
    selector: row=>row.id,
    sortable: true,
  },
  {
    name: 'Category',
    selector: row=>row.name,
    sortable: true,
  },
  {
    name: 'Action',
      cell: row => <Button color="danger" id={row.id} size="sm" onClick={(e)=>btnDelete(e,row.id)}>Delete</Button>,
      selector: row => row,
      width: '100px'
  },
];

class ListCategory extends Component {
	constructor(props){
		super(props);
		this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
		this.refreshData = this.refreshData.bind(this);
		this.setActiveCategory = this.setActiveCategory.bind(this);
		this.findByTitle = this.findByTitle.bind(this);
		this.removeAllCategories = this.removeAllCategories.bind(this);
		//this.btnDelete = this.btnDelete.bind(this);
		//this.laxman = this.laxman.bind(this);
		
		this.state = {
      currentCategory: null,
      currentIndex: -1,
      searchTitle: "",
    };
		
	}
	componentWillMount(){
		console.log('Testing showing data categories');
		this.props.retrieveCategories();
	
		//debugger;
		/*fetch('https://indiancitymarket.com/api/products')
		.then(res =>res.json())
		.then(data=>this.setState({products:data.products}))*/
	}
	onChangeSearchTitle(e) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle,
    });
  }

  refreshData() {
    this.setState({
      currentCategory: null,
      currentIndex: -1,
    });
  }

  setActiveCategory(category, index) {
    this.setState({
      currentCategory: category,
      currentIndex: index,
    });
  }

  removeAllCategories() {
	  alert("All category Delete")
    this.props
      .deleteAllCategories()
      .then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  findByTitle() {
    this.refreshData();

    this.props.findCategoriesByTitle(this.state.searchTitle);
  }
  render() {
	  console.log("Show now")
    const { match } = this.props;
	const { categories } = this.props;
	//console.log(categories)
    return (
      <Fragment>
	  <Button color="secondary" size="md" onClick={() => {
            this.props.history.goBack();
          }}
        >
          Back
        </Button>&nbsp;
		<Button color="warning" size="md">
          <Link to={`${match.path}/new`}>New Category</Link>
        </Button>{' '}
		<Button color="danger" size="md" onClick={this.removeAllCategories}>Remove All</Button>
		
		
		<ToastContainer />
		<div className="list row">
		<div className="col-md-2">Exact Match:</div>
		<div className="col-md-1"><input className="checkbox" type="checkbox" /></div>
		</div>
		<div className="list row">
        <div className="col-md-8">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search by title"
              value={this.state.searchTitle}
              onChange={this.onChangeSearchTitle}
            />
            <div className="input-group-append">
              <button
                className="btn btn-primary"
                type="button"
                onClick={this.findByTitle}
              >
			  Search
              </button>
            </div>
          </div>
        </div>
		</div>
<DataTable
        title="Category"
		primaryKey="id"
		customStyles={tableCustomStyles}
        columns={columns}
        data={categories}
        highlightOnHover
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 15, 25, 50,100,500]}
        paginationComponentOptions={{
          rowsPerPageText: 'Records per page:',
          rangeSeparatorText: 'out of',
        }}
      />
      </Fragment>
    );
  }
}

//export default ListProduct;
const mapStateToProps = (state) => {
	//console.log("State here")
	//console.log(state)
  return {
    categories: state.categories,
  };
};

export default connect(mapStateToProps, {
  retrieveCategories,
  findCategoriesByTitle,
  deleteCategory,
  deleteAllCategories,
})(ListCategory);
