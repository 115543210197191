import React from "react";
import ReactDOM from "react-dom";
import './config';
import { Provider } from 'react-redux';
import store from './store';
import App from "./App";

//export const MyContext = React.createContext();

//const store = createStore(rootReducer)
//const store = createStore()
//store.subscribe(()=>{ console.log("Testing store data: " + store.getState());})

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <App />
  </Provider>
  </React.StrictMode>,
  rootElement
);
